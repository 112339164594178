import React, {useState, useEffect, useRef} from 'react';
import {Form} from 'react-bootstrap';

import Sidebar from '../../Pages/Sidebar';
import {CustomSlidingButtons, Pannels} from '../../Components';
import {waveform, gridRound, rewardStar} from '../../Constants/Images';

import Moment from 'react-moment';

import DiscoverBuilders from '../../Components/SessionList/DiscoverBuilders';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import SendInvitation from './StaticPages/SendInvitation';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import AllBuilders from './StaticPages/AllBuilders';
import CallScreenModal from '../../Components/Modals/CallScreenModal';
import commonApi from '../../Services/CommonService';
import CallsInformation from './StaticPages/CallsInformation';
import {errorMessage} from '../../Utils/ToastMessages';
import CallRecordings from '../../Components/CallCenter/CallRecordings';
import SuggestionsListing from './StaticPages/SuggestionsListing';
import {useSelector} from 'react-redux';
import CallContacts from '../../Components/Contacts/CallContacts';
import ConfirmationModel from '../../Components/ConfirmationModel/ConfirmationModel';
import { getBuilders } from '../../Utils/commonUtils';
const CallCenter = props => {
  // const [threeDots, setThreeDots] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState('Dialler');
  const [showBuildersComponent, setShowBuildersComponent] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [buildersUserId, setBuildersUserId] = useState('');
  const [refresh, setRefresh] = useState([]);
  const [callModal, setCallModal] = useState(false);
  const [callHistoryList, setCallHistoryList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [authToken_Twillio, setAuthToken_Twillio] = useState('');
  const [checkUserHitApi, setCheckUserHitApi] = useState(false);
  const allBuilderList = useSelector(state => state.AllBuilderList);

  const handleButtonClick = digit => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber + digit);
    console.log(digit, 'digitdigit');
  };

  const handleDelete = () => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber.slice(0, -1));
  };
  const TabList = [
    {id: 'Dialler', title: 'Dialler'},
    {id: 'Contacts', title: 'Contacts'},
    {id: 'History', title: 'History'},
    {id: 'Recordings', title: 'Recordings'},
  ];

  const handleCall = () => {
    // Add logic for making a call with the phone number
    if (phoneNumber && phoneNumber !== '') {
      setCallModal(true);
      setSuggestions([])
    } else {
      errorMessage('Number is required');
    }
  };
  const handleContact = item => {
    if (item?.phone) {
      const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
      const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);

      if (cleanedPhoneNumber && onlyNumnbers) {
        setPhoneNumber(cleanedPhoneNumber);
        setShowComponent('Dialler');
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setPhoneNumber('');
      errorMessage('User not contain number ');
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    // Check if the input value is a number and has at most 15 digits
    if (/^[\d+#*]{0,15}$/.test(value)) {
      setPhoneNumber(value);
      const getBuiders = getBuilders(value, allBuilderList, 'callCenter')
      setSuggestions(getBuiders);
    }
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Response Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Frequency',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const handleType = e => {
    const {id, value} = e.target;
    setShowComponent(value);
  };
  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  const buildersHandle = (t, id, item) => {
    if (t === 'profileComponent') {
      if (item?.phone) {
        const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
        const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);

        if (cleanedPhoneNumber && onlyNumnbers) {
          setPhoneNumber(cleanedPhoneNumber);
          // check_users_in_company(cleanedPhoneNumber)
          setShowComponent('Dialler');
        } else {
          setPhoneNumber('');
          errorMessage('User not contain number ');
        }
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setBuildersUserId(id);
      setShowBuildersComponent(t);
    }
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
    // check_user_number_exist();s
  }, [showComponent]);

  useEffect(() => {
    if (showComponent !== 'Dialler') {
      setPhoneNumber('');
      setSuggestions([]);
    }
  }, [showComponent]);

  const check_users_in_company = searchQuery => {
    const payload = {search_query: searchQuery};

    commonApi
      .check_users_in_company(payload)

      .then(res => {
        setSuggestions(res?.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const check_user_number_exist = phone_number => {
    const payload = {phone_number: phone_number};

    commonApi
      .check_user_in_company(payload)

      .then(res => {
        if (res.status === 200 && res.user_found) {
          setPhoneNumber('');
        } else {
          setShowModel('inviteModl');
          console.log(phoneNumber,'phoneNumberphoneNumberphoneNumber')
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleSuggestionsClick = (item, cleanedPhoneNumber) => {
    setPhoneNumber(cleanedPhoneNumber);
    setSuggestions([]);
  };

  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(null);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef.current]);

  useEffect(()=>{
    get_twilio_call_access_token()
  },[])

  const get_twilio_call_access_token = phoneNumber => {
    // const payload = {phone_number: '+923045485310'};
    const payload = {phone_number: localStorage.getItem('loginNum')};
    commonApi
      .get_twilio_call_access_token(payload)
      .then(res => {
        if (res.status === 200) {
          setAuthToken_Twillio(res.token);
         
        }
      })
      .catch(err => console.error('Error fetching Twilio token:', err));
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setRefresh}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div>
                    <DiscoverBuilders
                      buildersHandle={buildersHandle}
                      buildersUserId={buildersUserId}
                      apiHit={refresh}
                      checkUserHitApi={checkUserHitApi}
                    />
                  </div>
                  <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                    <CustomSlidingButtons
                      items={TabList}
                      activeButtons={showComponent}
                      onItemClick={e => {
                        handleType(e);
                      }}
                      showItems={3}
                      value={showComponent}
                    />
                  </div>

                  {/* {showComponent === 'Contacts' &&
                  <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                  <Form.Group className="search-input">
                    <Form.Control
                      type="text"
                      placeholder="Search numbers..."
                      value={searchQry}
                      onChange={e => {
                        handleSearchQry(e.target.value);
                      }}
                    />
                  </Form.Group>
                  </div>
              } */}

                  <Scrollbars
                    autoHide
                    renderTrackHorizontal={props => (
                      <div {...props} style={{display: 'none'}} />
                    )}
                    // Hide the vertical scrollbar
                    renderTrackVertical={props => (
                      <div {...props} style={{display: 'none'}} />
                    )}>
                    <div
                      className="primary_panel_wraper"
                      style={{paddingTop: '0px'}}>
                      <div className="call-center-page">
                        {showComponent === 'Dialler' && (
                          <>
                            <div className="messages-header">
                              {/* <div className="event-tabs dark-tabs"> */}
                              <div
                                className="tabs-style-2"
                                style={{margin: '0px', width: '100%'}}>
                                <div
                                  className="phone-input"
                                  style={{
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}>
                                  <div>
                                    <div
                                      className="search-tabs-wrapper"
                                      style={{margin: '0px'}}>
                                      <div className="search-form d-flex mt-0">
                                        <Form.Group className="search-input-location">
                                          <div className="form-control-with-icon phone_placeholder">
                                            <Form.Control
                                              ref={inputRef}
                                              style={{
                                                color:
                                                  phoneNumber !== ''
                                                    ? ''
                                                    : '#AEAEAE',
                                                fontFamily: 'Inter',
                                                fontStyle: 'normal',
                                                fontWeight: '300',
                                                lineHeight: 'normal',
                                                fontSize: '15px',
                                                background: 'white',
                                                width: '310px',
                                                borderRadius: '5px',
                                                borderColor: '#eaeaea',
                                                margin: '0px',
                                              }}
                                              value={phoneNumber}
                                              onChange={handleChange}
                                              maxLength={15}
                                              type="text"
                                              placeholder="Enter Phone Number"
                                            />
                                            {phoneNumber !== '' && (
                                              <span
                                                className="input-icon"
                                                style={{left: '90%'}}
                                                onClick={() => {
                                                  setPhoneNumber('');
                                                  setSuggestions([]);
                                                }}>
                                                <i className="fas fa-close" />
                                              </span>
                                            )}
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className="phone-call-icon cursor-pointer"
                                      onClick={handleCall}>
                                      <i
                                        class="fa-solid fa-phone "
                                        style={{
                                          color: '#ffffff',
                                          fontSize: '1.5em',
                                        }}></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="search-tabs-icons">
                                <span
                                  onClick={() => {
                                    setThreeDots(!threeDots);
                                  }}
                                  className={
                                    threeDots
                                      ? 'icon active threeDots'
                                      : 'icon threeDots'
                                  }>
                                  <i className="fas fa-ellipsis-vertical" />
                                </span>
                              </div> */}
                            </div>

                            <SuggestionsListing
                              listing={suggestions}
                              type="callListing"
                              handleSuggestions={handleSuggestionsClick}
                              style={inputWidth}
                            />

                            {/* <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  padding: '20px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                  }}>
                                  <RoundButton
                                    className="call-center-num-btns"
                                    onClick={() => handleButtonClick('1')}
                                    style={roundButtonStyle}>
                                    1
                                  </RoundButton>
                                  <RoundButton
                                    className="call-center-num-btns"
                                    onClick={() => handleButtonClick('2')}
                                    style={roundButtonStyle}>
                                    2
                                    <span className="call-center-num-btns">
                                      A B C
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('3')}
                                    style={roundButtonStyle}>
                                    3
                                    <span className="call-center-num-btns">
                                      D E F
                                    </span>
                                  </RoundButton>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                  }}>
                                  <RoundButton
                                    onClick={() => handleButtonClick('4')}
                                    style={roundButtonStyle}>
                                    4
                                    <span className="call-center-num-btns">
                                      G H I
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('5')}
                                    style={roundButtonStyle}>
                                    5
                                    <span className="call-center-num-btns">
                                      J K L
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('6')}
                                    style={roundButtonStyle}>
                                    6
                                    <span className="call-center-num-btns">
                                      M N O
                                    </span>
                                  </RoundButton>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                  }}>
                                  <RoundButton
                                    onClick={() => handleButtonClick('7')}
                                    style={roundButtonStyle}>
                                    7
                                    <span className="call-center-num-btns">
                                      P Q R
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('8')}
                                    style={roundButtonStyle}>
                                    8
                                    <span className="call-center-num-btns">
                                      S T U
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('9')}
                                    style={roundButtonStyle}>
                                    9
                                    <span className="call-center-num-btns">
                                      W X Y Z
                                    </span>
                                  </RoundButton>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                  }}>
                                  <RoundButton
                                    onClick={() => handleButtonClick('+')}
                                    style={roundButtonStyle}>
                                    *
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('0')}
                                    style={roundButtonStyle}>
                                    0
                                    <span className="call-center-num-btns">
                                      +
                                    </span>
                                  </RoundButton>
                                  <RoundButton
                                    onClick={() => handleButtonClick('#')}
                                    style={roundButtonStyle}>
                                    #
                                  </RoundButton>
                                </div>
                              </div>
                            </div> */}
                          </>
                        )}

                        {showComponent === 'History' && <CallsInformation />}
                        {showComponent === 'Recordings' && <CallRecordings />}

                        {showComponent === 'Contacts' && (
                          <>                        
                          <CallContacts
                            contactListing={allBuilderList}
                            handleContact={handleContact}
                          /></>

                        )}

                        {/* </Scrollbars> */}
                      </div>
                    </div>
                  </Scrollbars>
                  {/* <div className="primary_panel_wraper"> */}
                  <div className="call-center-page"></div>

                  {callModal && (
                    <CallScreenModal
                      closeModel={() => {
                        setCallModal(false);
                        // setPhoneNumber('');
                        setSuggestions([]);
                      }}
                      show={callModal}
                      phoneNumber={phoneNumber}
                      check_user_number_exist={check_user_number_exist}
                      authToken_Twillio={authToken_Twillio}
                    />
                  )}

                  {showModel === 'inviteModl' && (
                    <ConfirmationModel
                      closeModel={() => {
                        setShowModel('');
                      }}
                      discription={'Will you invite this user in app ?'}
                      buttonOne={'Yes'}
                      buttonTwo={'No'}
                      ButtonOneAction={() => {
                        setShowBuildersComponent('inviteOutSideTheBuild');
                        setShowModel('');
                      }}
                    />
                  )}
                </>
              }
              secondary={
                <>
                  {showBuildersComponent === 'inviteOutSideTheBuild' && (
                    <SendInvitation
                      onClose={() => {
                        setShowBuildersComponent('');
                      }}
                      phoneNumber={phoneNumber}
                      setCheckUserHitApi={setCheckUserHitApi}
                      checkUserHitApi={checkUserHitApi}
                    />
                  )}
                  {buildersUserId &&
                    showBuildersComponent === 'profileComponent' && (
                      <div className="pb-4 fix-width-forms">
                        <ProfileComponent
                          userId={buildersUserId}
                          show={false}
                          onClose={() => {
                            setShowBuildersComponent('');
                          }}
                          button={''}
                          userData={[]}
                          moderator={false}
                          VoxeetSDK={[]}
                          conferenceInfo={[]}
                          jdSpecialM={[]}
                          jdSpecialA={[]}
                        />
                      </div>
                    )}

                  {showBuildersComponent === 'AllBuilders' && (
                    <div className="pb-4">
                      <AllBuilders
                        onClose={() => {
                          setShowBuildersComponent('');
                        }}
                      />
                    </div>
                  )}
                </>
              }
            />
            {showBuildersComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowBuildersComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CallCenter;
