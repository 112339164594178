import React, {useState, useEffect} from 'react';
import * as Twilio from '@twilio/voice-sdk';
import commonApi from '../../Services/CommonService';
import CustomModal from '../CustomModal';
import {formatTimeee} from '../../Utils/commonUtils';

const CallApp = () => {
  const [device, setDevice] = useState(null);
  const [callStatus, setCallStatus] = useState('Click to initialize');
  const [error, setError] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [IncomingCallModel, setIncomingCallModel] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isOnRecord, setIsOnRecord] = useState(false);
  const [recordingSid, setRecordingSid] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callAcepted, setCallAcepted] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState(null);

  const Token = localStorage.getItem('token')
  const Number = localStorage.getItem('loginNum')

  useEffect(() => {
    const interval = setInterval(() => {
      const Token = localStorage.getItem("token");
      const Number = localStorage.getItem("loginNum");

      if (Token && Number) {
        handleInitialize(); // Call the function once both are available
        clearInterval(interval); // Stop the interval after initialization
      }
    }, 500); // Check every 500ms

    // Cleanup on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleInitialize = async () => {
    const payload = {phone_number: localStorage.getItem('loginNum')};

    try {
      const token = await commonApi
        .get_twilio_call_access_token(payload)
        .then(res => res.token);
      setAuthToken(token);
      localStorage.setItem('twillio_Token', token);

      const deviceInstance = new Twilio.Device(token, {
        edge: 'roaming',
        logLevel: 1,
      });

      deviceInstance.register();

      deviceInstance.on('registered', () => {
        console.log('Device successfully registered.');
        setCallStatus('Registered and ready for calls');
      });

      deviceInstance.on('tokenWillExpire', async () => {
        console.log('Token is about to expire. Fetching a new token...');
        const newToken = await commonApi
          .get_twilio_call_access_token(payload)
          .then(res => res.token);

        localStorage.setItem('twillio_Token', newToken);

        deviceInstance.updateToken(newToken); // Update the device with the new token
        console.log('Device token updated successfully.');
      });
      deviceInstance.on('disconnect', () => {
        console.log('Call has been disconnected by the caller.');
        // Handle UI cleanup or other logic here
      });

      // Optional: Handle rejection or cancellation by the caller before being answered

      deviceInstance.on('incoming', call => {
        console.log('Incoming call:', call);
        setIncomingCall(call);
        setPhoneNumber(call.parameters.From || 'Unknown Caller');
        setIncomingCallModel(true);
        setCallStatus('Incoming call...');
        call.on('cancel', function () {
          console.log('The call was canceled by the other party.');
          // Additional logic for call cleanup or UI update
          setCallStatus('Call ended by the other party');
          setIncomingCallModel(null);
          setCallAcepted(false);
        });

        call.on('disconnect', function () {
          // Additional logic for call cleanup or UI update
          setCallStatus('Call disconnected');
          setIncomingCallModel(null);
          setCallAcepted(false);
        });
      });

      // deviceInstance.on('cancel', function () {
      //   console.log('The call was canceled by the other party.');
      //   // Additional logic for call cleanup or UI update
      //   setCallStatus('Call ended by the other party');
      // });

      // incomingCall.on('disconnect', function () {
      //   // Additional logic for call cleanup or UI update
      //   setCallStatus('Call disconnected');
      //   endCall();
      // });

      setDevice(deviceInstance);
    } catch (err) {
      console.error('Error initializing Twilio Device:', err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (isRecording && startTime && !isOnHold) {
      const interval = setInterval(() => {
        const durationsss = Math.floor((Date.now() - startTime) / 1000); // Calculate duration in seconds
        const getTime = duration + durationsss;
        setDuration(getTime); // Update the call duration
      }, 1000); // Update every second

      return () => clearInterval(interval); // Cleanup on unmount or state change
    } else {
      setIsRecording(false);
    }
  }, [isRecording, startTime, isOnHold]);

  const handleAcceptCall = () => {
    if (incomingCall) {
      // Accept the incoming call
      incomingCall.accept();
      setCallAcepted(true);

      setIsRecording(true);
      setStartTime(new Date());

      // Listen for the 'cancel' event when the other party ends the call
      incomingCall.on('cancel', function () {
        console.log('The call was canceled by the other party.');
        // Additional logic for call cleanup or UI update
        setCallStatus('Call ended by the other party');
      });

      // Optionally, you can also listen for the 'disconnect' event
      incomingCall.on('disconnect', function () {
        // Additional logic for call cleanup or UI update
        setCallStatus('Call disconnected');
        endCall();
      });

      // Update call status
      setCallStatus('Call connected');
    }
  };

  const handleRejectCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      setIncomingCall(null);
      setIncomingCallModel(false);
      setCallStatus('Call rejected');
    }
  };

  const startRecordingCall = () => {
    const payload = {
      auth_token: authToken,
      call_sid: incomingCall?.parameters.CallSid,
    };

    commonApi
      .start_recording_call(payload)
      .then(res => {
        if (res.status === 200) {
          setRecordingSid(res.recording_sid);
          setIsOnRecord(true);
        }
      })
      .catch(err => console.error('Error starting call recording:', err));
  };

  const stopRecordingCall = type => {
    const payload = {
      auth_token: authToken,
      call_sid: incomingCall?.parameters.CallSid,
      recording_sid: recordingSid,
    };

    commonApi
      .stop_recording_call(payload)
      .then(res => {
        if (res.status === 200) {
          setIsOnRecord(false);
        }
        if (type === 'endRecording') {
          endCallWithStopRecording();
        }
      })
      .catch(err => console.error('Error stopping call recording:', err));
  };

  const toggleMute = () => {
    if (incomingCall) {
      const newMuteState = !isMuted;
      incomingCall.mute(newMuteState);
      setIsMuted(newMuteState);
    }
  };

  const toggleHold = () => {
    if (incomingCall) {
      const newHoldState = !isOnHold;
      incomingCall.mute(newHoldState);
      setIsOnHold(newHoldState);
      setStartTime(new Date());
      setIsRecording(true);
    }
  };

  const endCall = () => {
    if (incomingCall && isOnRecord) {
      stopRecordingCall('endRecording');
    } else if (incomingCall) {
      incomingCall.disconnect();
      setCallStatus('Call ended');
      setIncomingCall(null);
      setIncomingCallModel(null);
      setCallAcepted(false);
      setIsRecording(false);
      setDuration(null);
    }
  };

  const endCallWithStopRecording = () => {
    incomingCall.disconnect();
    setCallStatus('Call ended');
    setIncomingCall(null);
    setIncomingCallModel(null);
    setCallAcepted(false);
    setIsRecording(false);
    setDuration(null);
  };
  return (
    <div>
      {IncomingCallModel && (
        <CustomModal size="small" hideCrossIcon={true}>
          <div className="call-screen">
            <h3 style={{lineBreak: 'anywhere'}} className="phone-number">
              {phoneNumber}
            </h3>
            <p className="status-text">{callStatus}</p>
            <p className="status-text" style={{marginBottom: '0px'}}>
              {duration ? formatTimeee(duration) : ''}
            </p>

            <div className="controls">
              <button className="control-button" onClick={toggleMute}>
                {!isMuted ? (
                  <svg
                    viewBox="0 0 384 512"
                    fill="currentColor"
                    height="21px"
                    width="21px">
                    <path d="M192 0c-53 0-96 43-96 96v160c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464h-48c-13.3 0-24 10.7-24 24s10.7 24 24 24h144c13.3 0 24-10.7 24-24s-10.7-24-24-24h-48v-33.6c85.8-11.7 152-85.3 152-174.4v-40c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128S64 326.7 64 256v-40z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21px"
                    height="21px"
                    fill="currentColor"
                    class="bi bi-mic-mute"
                    viewBox="0 0 16 16">
                    <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4 4 0 0 0 12 8V7a.5.5 0 0 1 1 0zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a5 5 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4m3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3" />
                    <path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607m-7.84-9.253 12 12 .708-.708-12-12z" />
                  </svg>
                )}
              </button>
              <button className="control-button" onClick={toggleHold}>
                {!isOnHold ? (
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    width="21" // Adjust as needed
                    height="21" // Adjust as needed
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="256"
                        y1="593.46"
                        x2="256"
                        y2="-81.46"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#231f20" />
                        <stop offset="1" stopColor="#58595b" />
                      </linearGradient>
                    </defs>
                    <path
                      d="M210.61,475.41A36.69,36.69,0,0,1,174,512H66.88a36.69,36.69,0,0,1-36.57-36.59V36.56A36.67,36.67,0,0,1,66.88,0H174a36.67,36.67,0,0,1,36.57,36.56ZM481.69,36.56A36.69,36.69,0,0,0,445.1,0H338a36.68,36.68,0,0,0-36.59,36.56V475.41A36.7,36.7,0,0,0,338,512H445.1a36.71,36.71,0,0,0,36.59-36.59Z"
                      fillRule="evenodd"
                      fill="url(#linear-gradient)"
                    />
                  </svg>
                ) : (
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    width="21"
                    height="21">
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="256"
                        y1="593.46"
                        x2="256"
                        y2="-81.46"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#231f20" />
                        <stop offset="1" stopColor="#58595b" />
                      </linearGradient>
                    </defs>
                    <path
                      d="M210.61,475.41A36.69,36.69,0,0,1,174,512H66.88a36.69,36.69,0,0,1-36.57-36.59V36.56A36.67,36.67,0,0,1,66.88,0H174a36.67,36.67,0,0,1,36.57,36.56ZM481.69,36.56A36.69,36.69,0,0,0,445.1,0H338a36.68,36.68,0,0,0-36.59,36.56V475.41A36.7,36.7,0,0,0,338,512H445.1a36.71,36.71,0,0,0,36.59-36.59Z"
                      fillRule="evenodd"
                      fill="none" // Makes the fill transparent
                      stroke="url(#linear-gradient)" // Optional: use gradient as stroke instead
                      strokeWidth="10" // Adjust stroke width as needed
                    />
                  </svg>
                )}
              </button>
              <button
                className="control-button"
                onClick={() => {
                  if (isOnRecord) stopRecordingCall();
                  else startRecordingCall();
                }}>
                {isOnRecord ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="none"
                    viewBox="0 0 16 16"
                    id="record">
                    <path
                      fill="red"
                      d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"></path>
                    <path
                      fill="white"
                      d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="none"
                    viewBox="0 0 16 16"
                    id="record">
                    <path
                      fill="#212121"
                      d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"></path>
                    <path
                      fill="#212121"
                      d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"></path>
                  </svg>
                )}
              </button>
            </div>

            <div className="flex flex-row justify-center content-between gap-12">
              <button
                className="end-call-button"
                onClick={() => {
                  if (callAcepted) {
                    endCall();
                  } else {
                    handleRejectCall();
                  }
                }}>
                <svg
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  height="22px"
                  width="22px">
                  <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" />
                </svg>
              </button>
              {!callAcepted && (
                // <div style={{width: '50%', height: '50%'}}
                //   className="call-center-page phone-call-icon cursor-pointer flex items-center justify-center bg-green-500 rounded-full"
                //   onClick={handleAcceptCall}>
                <div
                  style={{marginTop: '12px'}}
                  className="flex flex-row justify-center content-between gap-12">
                  <button
                    className="accept-call-button"
                    onClick={handleAcceptCall}>
                    <svg
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      height="22px"
                      width="22px">
                      <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" />
                    </svg>
                  </button>

                  {/* <i className="fa-solid fa-phone text-white text-lg"></i> */}
                </div>
              )}
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default CallApp;
