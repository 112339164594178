import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels, DiscoverBuilders} from '../../Components';
import {
  ArrowIcon,
  Bandage,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  bagIcon,
  heartIcon,
  listIcon,
  rewardStar,
  timeIcon,
} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import CustomersList from './CustomersList';
import {Scrollbars} from 'react-custom-scrollbars';
import Slider from 'react-slick';
import commonApi from '../../Services/CommonService';
import CustomerSearchFilter from '../../Components/Customers/CustomerSearchFilter';
import {filter} from 'rxjs';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import {
  fixName,
  NoCustomerFound,
  newSingleChatroom,
  chatroomInfo,
  truncateTitle,
} from '../../Utils/commonUtils';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import ContactsEdit from '../../Components/Contacts/ContactsEdit';
import SendInvitation from './StaticPages/SendInvitation';

const Customer = props => {
  const [currentComponent, setCurrentComponent] = useState('news');
  const [showProperComponet, setProperComponent] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const [showFullScreen, setShowFullScreen] = useState(false);
  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [listingSearchQry, setListingSearchQry] = useState('');
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [buildersUserId, setBuildersUserId] = useState(false);
  const [customerData, setCustomerData] = useState({});

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [seconadaryComponent, setSecondaryComponent] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const buildersHandle = (t, id) => {
    setBuildersUserId(id);
    setProperComponent(t);
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Average Purchase',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Response Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Ratings ',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };

  console.log(customerList, 'customerList');

  useEffect(() => {
    setShowSpinner(true);
    get_customer_list(1, '', filter);
    get_company_users_role();
  }, []);

  useEffect(() => {
    if (seconadaryComponent !== '' && seconadaryComponent !== 'firstVist') {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [seconadaryComponent]);

  
  const get_company_users_role = () => {
    commonApi
      .get_company_users_role()
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (res.data.length > 0) {
            const newFilterData = res.data.filter(
              item => item.role_type_id === '1',
            );
            const linkedin = {id: 'linkedin', role_alias: 'Linkedin link'};

            newFilterData.unshift(linkedin);
            setFilterList(newFilterData);
          } else {
            setFilterList([]);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);

            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);

            // setChatType('personal-single');
            // setChatDetails(response.chat_detail);
            // setAllChatUsers(allChatUsers);
            // setChatRoomName(response.chat_room_name);
            // setChatRoomId(response.chat_room_id);
            setSecondaryComponent('single_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([customerData]);
            setChatRoomName('');
            setChatRoomId('');

            setSecondaryComponent('single_chat');

            // setChatType('new-personal-single');
            // setChatDetails([]);
            // setAllChatUsers([userInfo]);
            // setChatRoomName('');
            // setChatRoomId('');
            // setShowComponent('single_chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };


  useEffect(()=>{
    get_customer_list(1, listingSearchQry);
  },[listingSearchQry])

  useEffect(()=>{
    setListingSearchQry('')
  },[showModal])


  const get_customer_list = (pageNo, searchQuery, Userfilter) => {

    const payload = {
      alias_ids: Userfilter ? Userfilter : filter,
      search: searchQuery ? searchQuery : listingSearchQry,
      page: pageNo ? pageNo : page,
    };

    commonApi
      .get_customer_list(payload)
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (pageNo > 1 && !searchQuery && !listingSearchQry) {
            const NewPushdATA = [...customerList, ...res.data];
            setCustomerList(NewPushdATA);
            setPage(pageNo ? pageNo : 1);
          } else {
            setCustomerList(res.data);
            setPage(1);
          }

          setIsFetching(false);
          setShowSpinner(false);
        }
      })
      .catch(err => {
        console.error('Error fetching customer list:', err);
        // Handle error appropriately, e.g., show error message to user
        return {type: 'error', message: err.message};
      });
  };

  const ProfileCard = ({profile}) => {
    console.log('updated ');
    return (
      <div className="payment-card-main as-link">
        <div
          className="payment-content-card"
          onClick={() => {
            if (profile.scanned_link) {
              setSecondaryComponent('editContacts');
              setSelectedItem(profile);
            } else {
              buildersHandle('profileComponent', profile.user_id);
            }
          }}>
          <div className="task-profile-img">
            <img
              src={profile.profile_img ? profile.profile_img : DEFAULT_IMAGE}
              alt={`${profile.first_name} ${profile.last_name}`}
            />
          </div>
          <div className="task-content">
            <h1
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                marginBottom: '4px',
                whiteSpace: 'normal',
                wordBreak: 'break-all',
              }}>
              {profile.scanned_link ||
                fixName(profile.first_name, profile.last_name) ||
                ''}
            </h1>
            <span
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                marginBottom: '4px',
              }}>
              {profile.home_location || profile.location_captured || ''}
            </span>
            {!profile.location_captured && (
              <span
                className="project-title"
                style={{
                  color: '#707070',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  marginBottom: '4px',
                }}>
                10 purchases
              </span>
            )}
          </div>
        </div>
        {!profile.location_captured && (
          <>
            <div
              className="payment-service-icons payment-service-customer-icons-holder"
              onClick={() => {
                buildersHandle('profileComponent', profile.user_id);
              }}>
              <div className="roi-service-icons">
                {Object.entries(profile.tags).length > 0 &&
                  profile.tags.slice(0, 6).map((item, index) => (
                    <div className="service-icon-time" key={index}>
                      {item.icon && (
                        <img className="tags_img" src={item.icon} alt="" />
                      )}
                      {item.title && (
                        <span className="tags_title">
                          {truncateTitle(item.title, 4)}
                        </span>
                      )}
                    </div>
                  ))}
                {profile.tags.length > 6 && (
                  <div className="w-29">
                    <i
                      className="fa-solid fa-ellipsis"
                      style={{
                        color: 'rgb(10, 10, 10)',
                        paddingTop: '15px',
                      }}></i>
                  </div>
                )}
              </div>
            </div>
            <div className="payment-card-bottom">
              <div
                onClick={() => {
                  buildersHandle('profileComponent', profile.user_id);
                }}
                className="payment-card-icons"
                style={{
                  color: '#262626',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  paddingTop: '11px',
                }}>
                Top 10%
              </div>
              <div className="contacts-item-icon no-link">
                <div
                  onClick={() => {
                    buildersHandle('profileComponent', profile.user_id);
                  }}
                  className="contacts-icon as-link">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div
                  className="contacts-icon as-link"
                  onClick={() => {
                    setCustomerData({
                      id: profile.user_id,

                      profile_img: profile.profile_img,
                      first_name: profile.first_name,
                      last_name: profile.last_name,
                    });
                    checkChatRoom(profile.user_id);
                  }}>
                  <i className="fa-solid icon-plane"></i>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + 2 + scrollTop >= scrollHeight && !isFetching) {
      // setShowBottomSpinner(true);
      // Increment the page number and call the API
      setIsFetching(true);

      get_customer_list(page + 1, listingSearchQry);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setProperComponent}
          showComponent={showProperComponet}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            {!showFullScreen && <div className="add_border"></div>}

            {!showFullScreen && (
              <Pannels
                isFloated={false}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                customCss={dynamicStyle}
                primary={
                  <>
                    <div className="discoverbuilder_width">
                      <DiscoverBuilders
                        buildersHandle={buildersHandle}
                        apiHit={workSpaceItem}
                      />
                    </div>

                    <div className="primary_panel_wraper referal_page_content">
                      <div className="search-tabs-wrapper">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    currentComponent === 'news' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setCurrentComponent('news');
                                  }}>
                                  Contacts
                                </Button>
                              </li>
                              {/* <li>
                                <Button
                                  className={
                                    currentComponent === '1122' ? 'active' : ''
                                  }>
                                  Project
                                </Button>
                              </li> */}
                            </ul>
                          </div>
                          <div className="search-tabs-icons">
                            {!showFullScreen && (
                              <span
                                onClick={() => {
                                  setShowFullScreen(!showFullScreen);
                                }}
                                className="icon">
                                <img
                                  src={listIcon}
                                  alt=""
                                  className="as-link"
                                />
                              </span>
                            )}
                            <span
                              className={
                                seconadaryComponent === 'inviteUser'
                                  ? 'icon active'
                                  : 'icon'
                              }>
                              <i
                                className="icon-plus"
                                onClick={() => {
                                  if (seconadaryComponent === '') {
                                    setSecondaryComponent('inviteUser');
                                  } else {
                                    setSecondaryComponent('');
                                  }
                                }}
                              />
                            </span>

                            <span
                              onClick={() => {
                                setThreeDots(!threeDots);
                              }}
                              className={
                                threeDots
                                  ? 'icon active threeDots'
                                  : 'icon threeDots'
                              }>
                              <i className="fas fa-ellipsis-vertical" />
                            </span>
                          </div>
                        </div>
                      </div>

                      {threeDots && (
                        <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Who are you looking for?"
                                value={listingSearchQry}
                                onChange={e => {
                                  setListingSearchQry(e.target.value);
                                  
                                }}
                              />
                            </Form.Group>
                            {filterList?.length > 0 && (
                              <Button>
                                <i
                                  className="icon-filter-icon"
                                  onClick={() => setShowModal('serach_filter')}
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="refferal-list-wrapper">
                        {showSpinner && <LoaderSpinner />}
                        <Scrollbars
                          className="custom-refferal-vertical-scrollbar"
                          autoHide
                          onScroll={handleScroll}>
                          {customerList && customerList.length > 0 ? (
                            customerList.map((profile, index) => (
                              <ProfileCard
                                key={`${index}${profile.user_id}`}
                                profile={profile}
                                customerList={customerList}
                              />
                            ))
                          ) : (
                            <NorecordFound noRecordData={NoCustomerFound} />
                          )}
                        </Scrollbars>
                      </div>
                    </div>

                    {showModal === 'serach_filter' && (
                      <CustomerSearchFilter
                        show={showModal}
                        onClose={() => setShowModal('')}
                        onCloseHitApi={userFilter => {
                          setShowModal('');
                          get_customer_list(1, '', userFilter);
                        }}
                        setFilter={setFilter}
                        filter={filter}
                        filterList={filterList}
                        setFilterList={setFilterList}
                        setshowLoader={setShowSpinner}
                      />
                    )}
                  </>
                }
                secondary={
                  <>
                    {seconadaryComponent === 'editContacts' && (
                      <ContactsEdit
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                        selectedItem={selectedItem}
                      />
                    )}
                    {buildersUserId &&
                      showProperComponet === 'profileComponent' && (
                        <div className="pb-4 fix-width-forms">
                          <ProfileComponent
                            userId={buildersUserId}
                            show={false}
                            onClose={() => {
                              setProperComponent('');
                            }}
                            // hideCloseButton={true}
                            button={''}
                            userData={[]}
                            moderator={false}
                            // setParticipantType={setParticipantType}
                            VoxeetSDK={[]}
                            conferenceInfo={[]}
                            jdSpecialM={[]}
                            jdSpecialA={[]}
                          />
                        </div>
                      )}

                    {seconadaryComponent === 'single_chat' && (
                      <SmartChatPage
                        allmsg={true}
                        type={chatType}
                        item={[]}
                        chatroomInfo={
                          chatDetails.length > 0
                            ? chatroomInfo(chatDetails)
                            : newSingleChatroom(customerData)
                        }
                        oppoDetails={chatDetails}
                        chatDetails={chatDetails}
                        allChatUsers={allChatUsers}
                        chatRoomName={chatRoomName}
                        chatRoomId={chatRoomId}
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                        userData={customerData}
                        // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                      />
                    )}

                    {seconadaryComponent === 'inviteUser' && (
                      <SendInvitation
                        hitApi={true}
                        onCloseHitApi={() => {
                          get_customer_list(1, '', filter);
                        }}
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                      />
                    )}
                  </>
                }
              />
            )}

            {showFullScreen && (
              <CustomersList
                showFullScreen={showFullScreen}
                setShowFullScreen={setShowFullScreen}
                analycticsSlider={analycticsSlider}
                setAnalycticsSlider={setAnalycticsSlider}
              />
            )}
            {showProperComponet === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setProperComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
