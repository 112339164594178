import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { errorMessage } from '../Utils/ToastMessages';

const NewMultiDropdown = ({
  setDropdownIds,
  dropdownIds,
  DropDownValues,
  customFunction,
  itemName,
  ErrorMessage,
  shadow,
}) => {


  const NoRecord = [
    {
      id: 'No deliverable available',
      title: 'No deliverable available',
    }
  ];
  const getItemName = (item, keys) => {
    for (let key of keys) {
      if (item[key]) {
        return item[key];
      }
    }
    return 'Unknown';
  };

  useEffect(() => {
    if(DropDownValues?.length > 0){
    setDropdownIds(DropDownValues.map(item => item.id));
    }else{
      setDropdownIds(NoRecord?.map((item)=>item.id))
    }
  }, [DropDownValues, setDropdownIds]);

  const handleSelection = eventKey => {
    const groupIds = [...dropdownIds];

    if(DropDownValues?.length > 0){
    if (eventKey === 'all') {
      if (dropdownIds.length === DropDownValues.length) {
        errorMessage(ErrorMessage)
        // setDropdownIds([]); // Deselect all if all are selected
      } else {
        setDropdownIds(DropDownValues.map(item => item.id)); // Select all
      }
    } else {
      if (groupIds.includes(eventKey)) {
        const newIds = groupIds.filter(id => id !== eventKey);

        if (newIds.length === 0) {
          errorMessage(ErrorMessage);
          return;
        }
        setDropdownIds(newIds);
      } else {
        setDropdownIds([...groupIds, eventKey]);
      }
    }
  }else{
    if (groupIds.includes(eventKey)) {
      const newIds = groupIds.filter(id => id !== eventKey);
      setDropdownIds(newIds);
    } else {
      setDropdownIds([...groupIds, eventKey]);
    }
  }
  };

  const displayText = () => {
    if(DropDownValues?.length > 0){
    if (dropdownIds.length === DropDownValues.length) {
      return 'All';
    }
    const text =  dropdownIds.length > 0 && dropdownIds.map(id => {
      const item = DropDownValues.find(item => item.id === id);
      return item ? (customFunction ? customFunction(item) : getItemName(item, itemName)) : '';
    }).join(', ');

    return text.length > 20 ? `${text.slice(0, 20)}...` : text || 'All';
  }else{
    const text =  dropdownIds.length > 0 && dropdownIds.map(id => {
      const item = NoRecord.find(item => item.id === id);
      return item ? (customFunction ? customFunction(item) : getItemName(item, itemName)) : '';
    }).join(', ');

    return text.length > 20 ? `${text.slice(0, 20)}...` : text || 'Select Deliverable';
  }
  };

  return (
    <div className={`multiselection_dropdown select-wrapper ${shadow ? 'shadow-none' : ''}`}>
      <Dropdown autoClose="outside" onSelect={handleSelection} drop="down">
        <Dropdown.Toggle variant="primary" id="dropdown-autoclose-outside">
          {displayText()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {DropDownValues?.length > 0 &&
          <Dropdown.Item
            eventKey="all"
            active={dropdownIds.length === DropDownValues.length}
          >
            All
          </Dropdown.Item>
}
          {DropDownValues?.length > 0 ? DropDownValues.map((item, index) => (
            <Dropdown.Item
              key={`dropdown-item-${index}`}
              eventKey={item.id}
              active={dropdownIds.includes(item.id)}
            >
              {customFunction ? customFunction(item) : getItemName(item, itemName)}
            </Dropdown.Item>
          ))
        :
        NoRecord.map((item, index)=> (
          <Dropdown.Item
          key={`dropdown-item-${index}`}
          eventKey={item.id}
          active={dropdownIds.includes(item.id)}
          >
          {getItemName(item, itemName)}
        </Dropdown.Item>
        ))   
        }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NewMultiDropdown;
