import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';

import {useState, useEffect, useRef} from 'react';
import CustomTabs from '../CustomTabs';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo, Templates} from '../../Constants/Images';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {capitalizeFirst, fixName} from '../../Utils/commonUtils';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';
import CustomSlidingButtons from '../CustomSlidingButtons';
import Invite from '../SingleComponent/Invite';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import commonApi from '../../Services/CommonService';
import IconModal from '../SessionDetails/IconModal';

const CreateProject = props => {

  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [subTabType, setSubTabsType] = useState('execute');
  const [projectName, setProjectName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [pocName, setPocName] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [pocList, setPocList] = useState([]);

  const [nameError, setNameError] = useState(false);
  const [pocError, setPocError] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState('');
  const [tagTitleList, setTagTitleList] = useState([]);

  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('1');
  const [threeDots, setThreeDots] = useState(false);
  const [actionTypeValue, setActionTypeValue] = useState(['Initialized']);
  const [actionType, setActionType] = useState([1]);
  const [showComponent, setShowComponent] = useState('');
  const [aliasRoles, setAliasRoles] = useState([]);
  const [pocAliase, setPocAliase] = useState([]);
  // Invite States
  const [builders, setBuilders] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [folowersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');
  const [listRoles, setListRoles] = useState([]);
  const [showSearchRoles, setShowSearchRoles] = useState([]);
  const [selectedAllAlias, setSelectedAllAlias] = useState([]);
  const [deletedType, setDeletedType] = useState('');
  const buttonRef = useRef(null);

  const [showModal, setShowModal] = useState(false)


  const dropdownValues = [
    {id: '1', title: 'Deliverables'},
    {id: '2', title: 'MileStones'},
    {id: '3', title: 'Tasks'},
    {id: '4', title: 'Uploads'},
  ];

  const filterOption = [
    {id: 1, title: 'Initialized'},
    {id: 2, title: 'Phase Change'},
    {id: 3, title: 'Canceled'},
  ];

  const handle = e => {
    const form = e.currentTarget;

    if (
      form.checkValidity() === false ||
      customersList.length === 0 ||
      pocList.length === 0 ||
      selectedAllAlias.length === 0
    ) {
      e.preventDefault();
      e.stopPropagation();

      if (customersList.length == 0 && form.checkValidity()) {
        errorMessage('Please select one customer');
      } else if (pocList.length === 0 && form.checkValidity()) {
        errorMessage('Please select one poc');
      } else if (selectedAllAlias.length === 0 && form.checkValidity()) {
        errorMessage('Please select one team');
        setTabType(labels.team[selected_lang]);
      }

      setValidated(true);
    } else {
      e.preventDefault();

      CreateProject();
    }
  };

  const handleInputChange = event => {
    setCustomerName(event.target.value);
  };
  const customerExist = (customersList, itemId) => {
    return customersList.some(customer => customer.id === itemId);
  };

  const PocExists = (pocList, itemId) => {
    return pocList.some(customer => customer.id === itemId);
  };

  const handleCustomer = item => {
    // const exist = customerExist(customersList, item.id)
    // console.log(exist,'===>exist')
    if (!customerExist(customersList, item.id)) {
      setCustomersList(prev => [...prev, item]);
      setCustomerName('');
      setAliasRoles([]);
    } else {
      errorMessage('You have Already added this Customer');
    }
  };

  const handlePoc = item => {
    // const exist = customerExist(customersList, item.id)
    // console.log(exist,'===>exist')
    if (!PocExists(pocList, item.id)) {
      setPocList(prev => [...prev, item]);
      setPocName('');
      setPocAliase([]);
    } else {
      errorMessage('You have Already added this Poc');
    }
  };

  const removeName = (MiniItem, dataType) => {
    if (dataType === 'CUSTOMERS_DELETE') {
      setCustomersList(previous =>
        previous.filter(item => item.id !== MiniItem.id),
      );
    } else if (dataType === 'POC_NAME_DELETE') {
      setPocList(previous => previous.filter(item => item.id !== MiniItem.id));
    } else if (dataType === 'ROLES_DELETE') {
      setSelectedAllAlias(previous =>
        previous.filter(item => item.id !== MiniItem.id),
      );
    }
    setShowDeleteModel('');
  };

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const get_customer_list = value => {
    const payload = {
      search_query: value,
    };

    commonApi
      .get_customers_for_project(payload)
      .then(res => {
        if (res.status === 200) {
          console.log(res, 'rrrrrrrrrr');

          setAliasRoles(res.data.filter(item => item.role_id));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(aliasRoles, 'ooooooooo');

  const get_all_team_for_project = value => {
    const payload = {search_query: value};

    commonApi
      .get_all_team_for_project(payload)
      .then(res => {
        if (res.status === 200) {
          setPocAliase(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProjeectButtons = profile => (
    <>
    
        <p className="p-bold m-0 f_600_h"
        style={{cursor: 'pointer'}}
           onClick={()=>{
            setShowModal(true)
          }}>Tag Icon</p>
        {/* <img src={RoiLogo} alt="" /> */}
   
    </>
  );

  console.log(showModal, 'showModal')

  const get_company_users_role = value => {
    const payload = {search: value};
    commonApi
      .get_company_users_role(payload)
      .then(res => {
        if (res.status === 200) {
          setListRoles(res.data.filter(item => item.role_type_id !== '1'));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleAddInArray = array => {
    const idExists = selectedAllAlias.some(alias => alias.id === array.id);

    if (idExists) {
      errorMessage('This role already exists.');
    } else {
      selectedAllAlias.push({
        id: array.id,
        role_alias: array.role_alias,
      });
      setShowSearchRoles('');
      setListRoles([]);
    }
  };

  const handleShowModel = item => {
    // setSelectedData(item);
    // setShowModel('show_delete_model');
  };

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  

  const CreateProject = () => {
    const teamIds = selectedAllAlias.map(alias => alias.id);
    const pocIds = pocList.map(poc => poc.id);
    const customerIds = customersList.map(customer => customer.id);
    

    const payload = {
      name: projectName,
      team_alias: teamIds.toString(),
      customer_ids: customerIds.toString(),
      poc_ids: pocIds.toString(),
      tag_titles:tagTitleList,
    };

    commonApi
      .create_update_projects(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {

             props.handleProjectAddEdit(res.data,'add')
            successMessage(res.message);
            props.onCloseApiHit();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderProjeectButtons}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}>
        <div
          className="serach-filter-wrapper create-sessionEvents"
          style={{
            // paddingLeft: '23px',
            paddingTop: '20px',
          }}>
          <div className="create-ticket-form  primary_panel_content">
            <div className="messages-header mb-2 fixed-width-inputs black-head-form-pad-left">
              <div className="event-tabs tabs-style-2 mb-2">
                {' '}
                <ul>
                  <li className="f_700">
                  {props.editProject ? 'Update Project' : 'Create Project'}
                  
                  </li>
                </ul>
              </div>

              <div className="search-tabs-icons">
                <img
                  className="template-img"
                  src={Templates}
                  alt=""
                  onClick={() => {}}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center  w-100">
              <div
                className="create-ticket-tabs custom-create-ticket-tabs"
                style={{
                  width: 'auto',
                }}>
                <CustomTabs
                  darkmode
                  active={tabType}
                  onSelect={item => setTabType(item)}
                  tabs={[
                    labels.sessions_type_general[selected_lang],
                    labels.team[selected_lang],
                    labels.TRIGGERS[selected_lang],
                  ]}
                />
              </div>
            </div>

            <div className="create-template refferal-list-wrapper">
              <Scrollbars autoHide>
                <div className="create-project-content fixed-width-inputs black-head-form-pad-left">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    {tabType ===
                      labels.sessions_type_general[selected_lang] && (
                      <div style={{marginBottom: '72px'}}>
                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Project Name
                          </Form.Label>
                          <Form.Control
                            name="title"
                            value={projectName}
                            pattern="^\S.*$"
                            required
                            placeholder="Add Project name"
                            onChange={e => {
                              setProjectName(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Project name is required.
                          </Form.Control.Feedback>
                        </div>

                        <div className="d-flex">
                          <div
                            className="fieldset flex-1"
                            style={{margin: '0px'}}>
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <Form.Label controlid="validationCustom01">
                                Customer
                              </Form.Label>
                              <span
                                className={'icon search-tabs-icons as-link'}
                                style={{marginRight: '5px',marginBottom:'4px'}}>
                                <i
                                  className="icon-plus"
                                  onClick={() => {
                                   setShowComponent('inviteuser')
                                  }}
                                />
                              </span>
                            </div>

                            <div className="input-plus-icon-holder">
                              <Form.Control
                                name="phase"
                                value={customerName}
                                // pattern="^\S.*$"/
                                placeholder="Customer Name"
                                // onChange={handleInputChange}
                                onChange={e => {
                                  setCustomerName(e.target.value);
                                  get_customer_list(e.target.value);
                                }}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Customer name is required.
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        {customerName && aliasRoles.length > 0 && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {aliasRoles.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  handleCustomer(suggestion);
                                }}>
                                {capitalizeFirst(
                                  suggestion.first_name +
                                    ' ' +
                                    suggestion.last_name,
                                )}
                              </li>
                            ))}
                          </ul>
                        )}

                        <div className="mb-3">
                          {Object.entries(customersList).length > 0 &&
                            customersList.map((item, index) => (
                              <div
                                class="d-flex align-items-center phases_item mt-3"
                                key={index}>
                                <div className="customer_name flex-1">
                                  <div className="alias_name">
                                    {fixName(item.first_name, item.last_name)}
                                  </div>

                                  <span
                                    class="as-link  cross-icon-field"
                                    onClick={() => {
                                      setDeletedType('CUSTOMERS_DELETE');
                                      setShowDeleteModel(item);
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div
                            className="fieldset flex-1"
                            style={{margin: '0px'}}>
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <Form.Label controlid="validationCustom01">
                                POC Name
                              </Form.Label>
                              {/* <span
                                className={'icon search-tabs-icons as-link'}
                                style={{marginRight: '5px'}}>
                                <i className="icon-plus" onClick={() => {}} />
                              </span> */}
                            </div>

                            <div className="input-plus-icon-holder">
                              <Form.Control
                                name="phase"
                                value={pocName}
                                // pattern="^\S.*$"
                                placeholder="Enter Name"
                                onChange={e => {
                                  setPocName(e.target.value);
                                  get_all_team_for_project(e.target.value);
                                }}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Poc name is required.
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        {pocName && pocAliase.length > 0 && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {pocAliase.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  handlePoc(suggestion);
                                }}>
                                {capitalizeFirst(
                                  suggestion.first_name +
                                    ' ' +
                                    suggestion.last_name,
                                )}
                              </li>
                            ))}
                          </ul>
                        )}

                        <div className="mb-3">
                          {Object.entries(pocList).length > 0 &&
                            pocList.map((item, index) => (
                              <div
                                class="d-flex align-items-center phases_item mt-3"
                                key={index}>
                                <div className="customer_name flex-1">
                                  <div className="alias_name">
                                    {fixName(item.first_name, item.last_name)}
                                  </div>

                                  <span
                                    class="as-link  cross-icon-field"
                                    onClick={() => {
                                      setDeletedType('POC_NAME_DELETE');
                                      setShowDeleteModel(item);
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>

                        <Button
                          className="btn-dark"
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}></Button>
                      </div>
                    )}

                    {tabType === labels.team[selected_lang] && (
                      <>
                        <div className="invite-page-search">
                          <div className="search-form mt-4">
                            <Form.Label
                              controlid="validationCustom01"
                              style={{
                                fontSize: '15px',
                                color: '#000000',
                                fontWeight: 500,
                              }}>
                              Roles
                            </Form.Label>
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                value={showSearchRoles}
                                onChange={e => {
                                  setShowSearchRoles(e.target.value);
                                  get_company_users_role(e.target.value);
                                }}
                                placeholder={'Search role'}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        {showSearchRoles && listRoles.length > 0 && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {listRoles.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  handleAddInArray(suggestion);
                                }}>
                                {capitalizeFirst(suggestion.role_alias)}
                              </li>
                            ))}
                          </ul>
                        )}
                        {Object.entries(selectedAllAlias).length > 0 ? (
                          selectedAllAlias.map((item, index) => (
                            <div
                              class="d-flex align-items-center gap-2 mt-3"
                              key={index}>
                              <div className="project_alias_role">
                                <div className="alias_name">
                                  {capitalizeFirst(item.role_alias)}
                                </div>
                              </div>

                              {/* <span
                                class="as-link ms-2"
                                onClick={() => {
                                  handleShowModel(item);
                                }}>
                                <i
                                  class="fas fa-times text-secondary"
                                  style={{fontSize: '1.5rem'}}></i>
                              </span> */}

                              <span
                                class="as-link  cross-icon-field"
                                onClick={() => {
                                  setDeletedType('ROLES_DELETE');
                                  setShowDeleteModel(item);
                                }}>
                                <i
                                  class="fas fa-times text-secondary"
                                  style={{fontSize: '1.5rem'}}></i>
                              </span>
                            </div>
                          ))
                        ) : (
                          <></>
                          // <> No Permissions Found </>
                        )}
                      </>
                    )}
                    {tabType === labels.TRIGGERS[selected_lang] && (
                      <>
                      <h4>Under Development</h4>
                        {/* <CustomDropdown
                          setDropdownId={setDropdownId}
                          dropdownId={dropdownId}
                          DropDownValues={dropdownValues}
                          itemName={['title']}
                          className={'mb-3'}
                        />

                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                          <CustomSlidingButtons
                            items={filterOption}
                            activeButtons={actionType}
                            onItemClick={e => {
                              handleType(e);
                            }}
                            showItems={3}
                            value={actionTypeValue}
                          />
                        </div>
                        <div className="messages-header mb-2 fix-width-forms">
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    subTabType === 'execute' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'execute') {
                                      setSubTabsType('execute');
                                    }
                                  }}>
                                  {labels.EXECUTE[selected_lang]}
                                </Button>
                              </li>

                              <li>
                                <Button
                                  className={
                                    subTabType === 'update' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'update') {
                                      setSubTabsType('update');
                                    }
                                  }}>
                                  {labels.UPDATE[selected_lang]}
                                </Button>
                              </li>

                              <li>
                                <Button
                                  className={
                                    subTabType === 'notify' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'notify') {
                                      setSubTabsType('notify');
                                    }
                                  }}>
                                  {labels.NOTIFY[selected_lang]}
                                </Button>
                              </li>
                            </ul>
                          </div>

                          <div className="search-tabs-icons">
                            <img
                              className="template-img as-link"
                              src={Templates}
                              alt=""
                              onClick={() => {}}
                            />
                            <span
                              onClick={() => {
                                setThreeDots(!threeDots);
                              }}
                              className={` mt-0  ${
                                threeDots ? 'icon active' : 'icon'
                              }`}>
                              <i className="fas fa-ellipsis-vertical" />
                            </span>
                          </div>
                        </div>
                        {subTabType === 'execute' && <></>}

                        {subTabType === 'update' && <></>}

                        {subTabType === 'notify' && <></>} */}
                      </>
                    )}
                  </Form>

                  <Button
                    style={{display: 'none', marginRight:'0px'}}
                    ref={buttonRef}
                    className="btn-dark m-0"
                    onClick={() => {
                      handleClick();
                    }}>
                    {props.editProject ? 'Update Project' : 'Create Project'}
                  </Button>
                </div>
              </Scrollbars>
            </div>
          </div>
          {tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper fix-width-forms mt-2">
              <div
                className="invite-btn text-center bg_transparent pb-2 pt-2"
                style={{bottom: 0}}>
                <Button
                  style={{marginRight:'0px'}}
                  className="btn-dark mb-0 mr-0"
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {/* {props.editAction ? 'Update Task' : } */}
                  {props.editProject ? 'Update Project' : 'Create Project'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showDeleteModel && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModel('');
          }}
          discription={`Are you sure you want to delete this 
            ${
              deletedType === 'CUSTOMERS_DELETE'
                ? 'customer'
                : deletedType === 'POC_NAME_DELETE'
                ? 'poc'
                : 'roles'
            } `}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeName(showDeleteModel, deletedType);
          }}
        />
      )}

      {showModal &&
      <IconModal
      closeModel={() => {
        setShowModal(false);
      }}
      show= {showModal}
      tagData={tagTitleList}
      setTagData={setTagTitleList}
       />
}

{showComponent === 'inviteuser' && (
                    <SendInvitation
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
    </>
  );
};

export default CreateProject;
