import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import CustomModal from '../CustomModal';
import { errorMessage } from '../../Utils/ToastMessages';

const IconModal = ({tagData,setTagData,closeModel}) => {
  // const [tagData, setTagData] = useState([]); 
  const [newTag, setNewTag] = useState(''); 
  const [editIndex, setEditIndex] = useState(null); 
  const [validated, setValidated] = useState(false);
  const buttonRef = useRef();

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handle = e => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
  
        if (tagData.length == 0 && form.checkValidity()) {
          errorMessage('Please write icon');
        } 
  
        setValidated(true);
      } else {
        e.preventDefault();
        const trimmedTitle = newTag.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
        errorMessage('Icon cannot be empty');
        return;
    }

        if (newTag) {
            if(!tagExists(newTag, editIndex)){
            if (editIndex !== null) {
              const updatedTags = [...tagData];
              updatedTags[editIndex] = newTag;
              setTagData(updatedTags);
              setEditIndex(null); 
            } else {
              setTagData([...tagData, newTag]); 
            }
            setNewTag(''); 
          }else{
            errorMessage('Icon already exists!')
          }
        }

      }
    
  };

  console.log(editIndex, 'editIndex')

  const tagExists = (value, id) => {
    console.log(value, 'ppppppppppp', id)
    return tagData.some((item,index )=> item === value && index  !== id);
  };


  const handleDeleteTag = value => {
    console.log(value, 'index')
    const updatedTags = tagData.filter((item) => item !== value); 
    setTagData(updatedTags);
    console.log(tagData, 'tagData')
  };


  const handleEdit = index => {
    console.log(index, 'pppppppppppp')
    setNewTag(tagData[index]); 
    setEditIndex(index); 
  };


  return (
    <CustomModal
      size="large"
      onClose={() => {
        closeModel();
      }}>
      <div className="create-template serach-filter-wrapper">
        <div className="create-project-content fix-width-forms filter-wrapper">
        <Form noValidate validated={validated} onSubmit={handle}>
            <Form.Label>Add Tags</Form.Label>
            <Form.Group className="form-group" controlId="tags">
              <textarea
                rows={5}
                name="tags"
                placeholder="Enter tags here"
                value={newTag}
                onChange={e => setNewTag(e.target.value)} 
              />
            </Form.Group>

            <div className="mb-5">
              {tagData.length > 0 && tagData.map((tag, index) => (
                <div className="d-flex align-items-center phases_item mt-3" key={index}>
                  <div className="customer_name flex-1">
                    <div className="alias_name">{tag}</div>
                    <div style={{display: 'flex'}}> 
                    <span
                      className="cross-icon-field"
                      >
                        <i
                        className="as-link fas fa-edit"
                        onClick={() => handleEdit(index)}
                        style={{ fontSize: '1.5rem' }}></i>
                      </span>
                      <span
                      className="cross-icon-field"
                      >
                         <i
                        className="as-link fas fa-times text-secondary"
                        onClick={() => handleDeleteTag(tag)}
                        style={{ fontSize: '1.5rem' }}></i>
                    </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Button 
            style={{ display: 'none' }} 
            ref={buttonRef} 
            className="btn-dark m-0" 
            type="submit"></Button>

          </Form>
        </div>
      </div>
      <div className="filter-btn-wrapper fix-width-forms mt-2">
        <div className="invite-btn text-center fix-width-forms bg_transparent pb-2 pt-2">
          <Button className="btn-dark mb-0" 
          type="submit" 
          onClick={handleClick}
          >
          {editIndex !== null ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default IconModal;
