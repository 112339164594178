import React, {useState, useEffect} from 'react';
import { fixName, formatTimeee } from '../../../Utils/commonUtils';
import commonApi from '../../../Services/CommonService';
import Moment from 'react-moment';
import { DEFAULT_IMAGE } from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import { twillio_icon } from '../../../Constants/Images';
import NorecordFound from '../../../Components/NoRecord/NorecordFound';
import { messagesRecordFound } from '../../../Utils/NoRecordConstant';

const CallsInformation = props => {

    const [callHistoryList, setCallHistoryList] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);

    const get_twilio_call = () => {

        commonApi
          .get_twilio_call()
          .then(res => {
            if (res.status === 200) {
              setCallHistoryList(res.call_details);
              setShowSpinner(false)
            }
          })
          .catch(err => console.error('Error fetching Twilio token:', err));
      }; 

      useEffect(()=>{
        get_twilio_call()
      },[])

  return (
    <>
              {showSpinner && <LoaderSpinner />}

    <div className="session-wrapper history-sessions cursor-default">
                            {Object.entries(callHistoryList).length > 0 ? (
                              Object.entries(callHistoryList).length > 0 &&
                              callHistoryList.map((item, index) => (
                                <div
                                  key={index + 'ASDSAD' + item.id}
                                  className="events-item"
                                  style={{padding: '11px', marginBottom: '10px'}}
                                  >
                                  <div className="session-heading" style={{alignItems: 'unset'}}>
                                    <div className="persons-img-list mb-0 d-flex align-items-center">
                                      <div className="img-item mb-0">
                                        <img
                                          src={item.receiver_detail?.profile_img ?
                                            item.receiver_detail?.profile_img
                                             : DEFAULT_IMAGE
                                          }
                                          alt=""
                                          style={{width: '60px', height: '60px'}}
                                        />
                                      </div>
                                      <div className='row'>
                                      <span className="m-1">
                                        {fixName(item?.receiver_detail?.first_name, item?.receiver_detail?.last_name)}
                                      </span>
                                      {item?.receiver_phone &&
                                      <span className="m-1">
                                        {item?.receiver_phone}
                                      </span>
                                        }
                                      </div>
                                    </div>
                                    <div className="row event-titles">
                                      <div className="event-date">
                                      {item?.status}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="session-heading" style={{marginTop: '20px', marginBottom: '0px'}}>
                                      <div className="event-titles">
                                       {item?.duration ? formatTimeee(item?.duration) : '00:00'}
                                        </div>
                                      <div className="event-date fs-8">
                                        <Moment format="MM/DD/YY H:mmA">
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <NorecordFound
                              noRecordData={messagesRecordFound}
                              />
                            )}
                          </div>
    </>
  );
};

export default CallsInformation;
