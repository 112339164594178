import {useEffect, useState} from 'react';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Moment from 'react-moment';
import {messagesRecordFound} from '../../Utils/NoRecordConstant';
import NorecordFound from '../NoRecord/NorecordFound';
import {fixName} from '../../Utils/commonUtils';

const TwillioSMS = props => {
  const {messages, showSpinner, setSecondComponent, setUserChat} = props;

  return (
    <>
   
        {showSpinner && <LoaderSpinner />}
        {!showSpinner && messages && messages.length > 0 ? (
          messages?.map((message, index) => (
            <div
              key={index}
              onClick={() => {
                setSecondComponent('showMessage');
                setUserChat(message?.chat_with);
              }}
              className="message-user-item no-link likes-dislikes-msgs">
              <div className="user-img as-link">
                <img
                  src={
                    message?.chat_with?.profile_img
                      ? message?.chat_with?.profile_img
                      : DEFAULT_IMAGE
                  }
                  alt={message?.chat_with?.first_name}
                />
              </div>
              <div className="user-content as-link">
                <h2 className="single-line-text">
                  {fixName(
                    message?.chat_with?.first_name,
                    message?.chat_with?.last_name,
                  )}
                </h2>
                <p className="single-line-text">
                  {message?.last_message?.message}
                </p>
                <div className="message-date-info">
                  <span className="message-date">
                    <Moment format="MM/DD/YY HH:mm A">
                      {message?.last_message?.timestamp}
                    </Moment>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NorecordFound noRecordData={messagesRecordFound} className={'pb-3 pt-3'} />
        )}
    
    </>
  );
};

export default TwillioSMS;
