import React, {useState} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';

const CustomDropdown = ({type,setDropdownId,dropdownId,DropDownValues,itemName, className,handleDropdownValue,disabled}) => {
  



  // const handleDropdownChange = id => {
  //   setDropdownId(id);
  // };

  const getItemName = (item, keys, ) => {
    for (let key of keys) {
      if (item[key]) {
        return item[key];
      }
    }
    return 'Unknown';
  };

  return (
    <div className={`roi-form-dropdown ${className ? className : '' }`}>
      <div className="select-wrapper">
    
   { Object.entries(DropDownValues).length > 0 &&
      <Dropdown
        onSelect={(eventKey, event) => {
          setDropdownId(eventKey);
          if(type==='credential' || type === 'milestone' || type === 'deliverable'){
            handleDropdownValue && handleDropdownValue(eventKey)
          }
        }}
        drop="down">
        <Dropdown.Toggle variant="primary" className={`${disabled ? 'disabled' : ''}`}>
          {dropdownId && type !== 'deliverable'
            ? getItemName(DropDownValues.find(item => item.id === dropdownId),itemName)
            : dropdownId && type === 'deliverable' ?
            getItemName(DropDownValues.find(item => item.title === dropdownId),itemName)

            : 'Select Role'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {DropDownValues.map((item, index) => (
            <Dropdown.Item
              key={`st-${index}`}
              eventKey={type === 'deliverable' ? item.title  :item.id.toString()}
              active={type === 'deliverable' ? item.title === dropdownId    : item.id === dropdownId}>
              {getItemName(item,itemName)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

        }
   </div>
  </div>
  );
};

export default CustomDropdown;
