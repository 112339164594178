import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { project_management, upload_file } from '../../Constants/Images';
import commonApi from '../../Services/CommonService';
import { errorMessage } from '../../Utils/ToastMessages';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import NorecordFound from '../NoRecord/NorecordFound';

const Upload = (props) => {

    const {
        setShowFileComponent, 
        uploadFilesListing, 
        setUploadFilesListing, 
        handleFiles, 
        editProject
    } = props;

    const [showLoader, setShowLoader] = useState(false);


    const upload_files_to_s3 = file => {
        setShowLoader(true);
        var bodyFormData = new FormData();
        bodyFormData.append(
          'file',
          file.replace(/^data:image\/[a-z]+;base64,/, ''),
        );
        commonApi
          .upload_files_to_s3(bodyFormData)
          .then(response => {
            if (response.status === 200 && response.success) {

                const fileInfo = [
                {
                  file: response.file,
                  extension: response.file_extension,
                  title: response.file_title,
                },
              ];
              console.log(fileInfo, 'fileInfo');
              setUploadFilesListing(fileInfo);
              console.log('chala');
              setShowLoader(false);
              handleFiles(response, 'upload')
            } else {
              if (response.message) {
                errorMessage(response.message);
                setShowLoader(false);
              }
            }
          })
          .catch(error => {
            return {type: 'error', message: error.message};
          });
      };


    const validateFileExtension = filename => {
        const allowedExtensions = [
          'doc',
          'docx',
          'xls',
          'xlsx',
          'pdf',
          'ppt',
          'pptx',
        ];
        const fileExtension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(fileExtension);
      };

    const handleFileUpload = event => {

        if (event.target.files.length === 0) return false;
        const file = event.target.files[0];
    
        if (file) {
          const isValid = validateFileExtension(file.name);
          if (isValid) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
              upload_files_to_s3(e.target.result);
            };
    
        } else {
            errorMessage(
              'Invalid file type. Please upload a Word, Excel, PDF, or PowerPoint file.',
            );
          }
        }
      };


return (

<>
<div className="upload-file-content upload">
    {editProject &&
  <div className="file-upload-wrapper as-link" style={{ marginBottom: 15 }}>
    <input
      type="file"
      className="as-link"
      accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx"
      onChange={e => {
        handleFileUpload(e);
      }}
    />
    <img src={upload_file} alt="" />
    <span>Tap to upload</span>
  </div>
}
    <>
    {uploadFilesListing.length > 0 &&
  <div>
    <h2 style={{ fontWeight: 600, fontSize: "17.05px", paddingBottom: 15 }}>
      Uploaded
    </h2>
  </div>
}
  {uploadFilesListing.length > 0 &&
  <div className="uploaded-files-wrapper">
    {uploadFilesListing && uploadFilesListing.length > 0 && uploadFilesListing.map((item)=>(
    <div className="uploaded-file mb-3 ">
    <div className="file-detail as-link">
    <div style={{display: 'flex', justifyContent: 'space-between'}}
    >
      <h1
       onClick={() => {
        handleFiles(item, 'open');
    }}
    >{item?.file_title}</h1>
      {editProject &&
      <span
      style={{margin: '-3%'}}
       onClick={() => {
        handleFiles(item, 'delete');
    }}>
      <i className="fa-solid fa-xmark"></i>
    </span>
}
    </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <span>Permits</span> */}
        <span style={{fontSize: '18px', marginRight: '-3%', padding: '10px'}} >{item?.file_extension}</span>
      </div>
    </div>
    {/* <div className="upload_file_bottom d-flex justify-content-between">
      <div className="file_upload_time">12/04/24 4:39 AM</div>
      <span className="share_icon as-link">
        <i className="fas fa-share" />
      </span>
    </div> */}
  </div>
    ))}
  </div>
}
  </>
  {!editProject && uploadFilesListing.length === 0 &&

  <NorecordFound
  noRecordData={{
    img: project_management,
    title:
      null,
    description:
      'No files available.',
    button:
      null,
  }}
/> 
}
  
</div>


</>
);
};
export default Upload;
